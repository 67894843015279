.dg-data-grid label {
  margin: 0;
}

.dg-data-grid .dg-checkbox {
  width: 100px;
}

.dg-data-grid .dg-checkbox {
  font-size: 0.875rem;
}

.dg-data-grid button.link {
  color: var(--color-gray-darkest);
}

.dg-data-grid button.active-column.link {
  color: var(--color-blue-dark);
}

.dg-data-grid button.link:hover {
  text-decoration: none;
}

.dg-data-grid-block {
  width: 100%;
  overflow: auto;
}
