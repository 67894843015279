body {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
}

.container {
  min-height: 90vh;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dl-flex-center {
  justify-content: center;
  align-items: center;
}

a {
  color: var(--color-blue-dark);
}

a:hover {
  color: var(--color-link-hover);
}

a:active {
  color: var(--color-link-pressed);
}

a:visited {
  color: var(--color-blue-dark);
}

.menu {
  border: 1px solid #ddd;
  background-color: #dddddd52;
  padding: 0px 0px 20px 13px;
  border-radius: 0px 0px 12px 12px;
}

.menu li {
  display: inline-block;
  padding: 20px 20px 0px 0px;
  font-size: 20px;
}
.menu li a {
  
  color: #6f6f6f;
}

.menu li a.active {
  font-weight: bold;
  text-decoration: underline;
  color: #0c7bab;
}
s-container {
  padding: 0px 12px;
}

.info{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.detail-info{
  border: 3px solid #ddd;
  border-radius: 12px;
  padding: 10px;
  margin: 10px 0px;
}